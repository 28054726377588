import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonBanner from "../components/ButtonBanner";
import "../styles/tech.scss";
import { Container, Image } from "react-bootstrap";

const Technology = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Understand AR VR Technology</title>
        <meta
          name="description"
          content="We've outlined the ar vr technology of our platform. It highlights how every brand can scale at speed and provide AR VR experiences to its users in seconds."
        />
        <meta
          name="keywords"
          content="ar vr technology, augmented reality technologies"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>
      <Container fluid className="tech">
        <div className="spacer d-md-none"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="container">
          <div className="spacer d-xl-none"></div>
          <div className="spacer-sm"></div>
          <div>
            <h1 className="h1 text-center">
              The scalable tech stack of HelloAR
            </h1>
            <div className="spacer-sm"></div>
            <div className="d-flex justify-content-center">
              <div className="w-50">
                <Image src="/images/techstack.jpg" fluid />
              </div>
            </div>
            <div className="spacer-sm"></div>
            <p className="bt-1 mt-5">
              Our unified 3D platform with multiple applications helps you ace
              the eCommerce space. You can use our solutions for any product,
              industry, or channel. Our solutions are being used by product,
              sales, and marketing teams for various purposes.
            </p>
          </div>
          <div className="spacer-sm"></div>
          <div className="section">
            <ul className="mt-3">
              <li className="row">
                <h2 className="col-12 col-md-3">Computer vision</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  We derive meaningful information from digital images and
                  videos to create an instant studio-grade 3D gallery of images
                  for your products.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">
                  Artificial Intelligence (AI)
                </h2>
                <p className="bt-1 col-12 col-md-9 main">
                  Our platform uses artificial intelligence to learn and speed
                  up the process. This helps you scale faster and with ease.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Deeptech</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  Our intelligent technology stack helps us in being flexible
                  and adapt our platform to any industry and any use case
                  according to the needs of our users{" "}
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">3D</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  The core of our solution lies in 3D product renders. All three
                  above-stated technologies help us convert your 2d images into
                  3D product renders instantly.{" "}
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">API and SDK</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  Our two principal tools enable us to enhance the functionality
                  of our application with relative ease to match your needs.
                  They help us build on the functionality you require.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">No Code Platform</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  We use no-code platforms to add interactions to our 3D product
                  renders. Our users can log in and create as many interactions
                  as they desire with just the drag and drop option.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">View Application</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  With the view application installed on your site, any user can
                  view 3D product renders (360-degree view of the product){" "}
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Interact</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  You can create product interactions and highlight unique
                  product features with this application
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Manipulate</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  This is where the product configurator steps in. Users can
                  manipulate products and change product specifications as per
                  their choice in real-time.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Channels</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  These applications (view, interact, and manipulate) are the
                  basis of creating AR, VR, or Metaverse experiences for your
                  users. You can choose to implement all or either of them.
                </p>
              </li>
              <div className="spacer-sm"></div>
            </ul>
          </div>
          <div className="spacer-sm"></div>
        </div>
        <ButtonBanner
          buttonTitle="Have a request? Email us"
          buttonUrl="/contact-us"
        >
          <h1 className="h1 text-center text-light px-3">
            Always Innovating. Always Creating.
            <br />
            Stay tuned for our upcoming additions to simplify your visual
            commerce journey.
          </h1>
        </ButtonBanner>
      </Container>
    </Layout>
  );
};

export default Technology;

// export const imgs = graphql`
//   query {
//     imageOne: file(relativePath: { eq: "techstack.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
